import {
  eachDayOfInterval,
  eachMonthOfInterval,
  format,
  startOfDay,
  startOfMonth,
  sub,
} from 'date-fns'
import type { Interval } from '@/api/statistics/interface'
export type ActiveFilters = 'dayFilter' | 'monthFilter' | 'yearFilter'

const DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
const START_DATE = '2021-06-01'
const startDate = new Date('2021-06-01')
const endDate = new Date()

export const getAllTimeInterval = (): Interval => {
  return {
    intervalName: 'allTimeInterval',
    startDateTime: format(new Date(START_DATE), DATE_FORMAT),
    endDateTime: format(new Date(), DATE_FORMAT),
  }
}
export const getLastMonthsInterval = (): Interval => {
  return {
    intervalName: 'lastMonthInterval',
    startDateTime: format(startOfMonth(new Date()), DATE_FORMAT),
    endDateTime: format(new Date(), DATE_FORMAT),
  }
}

export const getLastMonthsIntervals = (amount?: number): Interval[] => {
  const intervals: Interval[] = []

  let startDay = new Date(START_DATE)
  const currTime: any = new Date()

  let lastMonths: Date[] = []

  if (amount) {
    startDay = sub(currTime, { months: amount })
  }

  lastMonths = eachMonthOfInterval({
    start: startDay,
    end: currTime,
  })

  for (let i = 0; i < lastMonths.length; i++) {
    intervals.push({
      startDateTime: format(lastMonths[i], DATE_FORMAT),
      endDateTime: format(
        i < lastMonths.length - 1 ? lastMonths[i + 1] : currTime,
        DATE_FORMAT
      ),
      intervalName: format(lastMonths[i], DATE_FORMAT),
    })
  }

  return intervals
}

export const getLastDaysIntervals = (amount: number = 7): Interval[] => {
  const intervals: Interval[] = []

  const currTime: any = new Date()
  const startDay = sub(currTime, { days: amount })

  let lastDays: Date[] = []

  lastDays = eachDayOfInterval({
    start: startDay,
    end: currTime,
  })

  for (let i = 0; i < lastDays.length; i++) {
    intervals.push({
      startDateTime: format(lastDays[i], DATE_FORMAT),
      endDateTime: format(
        i < lastDays.length - 1 ? lastDays[i + 1] : currTime,
        DATE_FORMAT
      ),
      intervalName: format(lastDays[i], DATE_FORMAT),
    })
  }

  return intervals
}

export const getIntervalsWithFilter = (
  filter: ActiveFilters | 'lastsDayMonthYearFilter' | 'allTimeInterval'
): Interval[] => {
  if (filter === 'yearFilter') {
    return getLastMonthsIntervals()
  } else if (filter === 'monthFilter') {
    return getLastMonthsIntervals(7)
  } else if (filter === 'dayFilter') {
    return getLastDaysIntervals(7)
  } else if (filter === 'lastsDayMonthYearFilter') {
    const allTimeInterval: Interval = {
      intervalName: 'allTimeInterval',
      startDateTime: format(startDate, DATE_FORMAT),
      endDateTime: format(new Date(), DATE_FORMAT),
    }
    const lastDayInterval: Interval = {
      intervalName: 'lastDayInterval',
      startDateTime: format(startOfDay(new Date()), DATE_FORMAT),
      endDateTime: format(new Date(), DATE_FORMAT),
    }
    const lastMonthInterval: Interval = {
      intervalName: 'lastMonthInterval',
      startDateTime: format(startOfMonth(new Date()), DATE_FORMAT),
      endDateTime: format(new Date(), DATE_FORMAT),
    }
    return [lastDayInterval, lastMonthInterval, allTimeInterval]
  } else {
    // return interval all time as (filter === 'allTimeInterval')
    return [
      {
        intervalName: 'allTimeInterval',
        startDateTime: format(new Date(startDate), DATE_FORMAT),
        endDateTime: format(new Date(), DATE_FORMAT),
      },
    ]
  }
}

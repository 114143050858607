import {
  type BackendClient,
  SimpleBackendClient,
} from '@modules/backend-client'
import {
  SimpleTokenClient,
  type Token,
  type TokenClient,
  type TokenListener,
} from '@modules/token-client'

export type ServerError = {
  message: string
  timestamp?: string
  path?: string
  status?: number
  error?: string
}

// === local token utils
export const setLocalRefreshToken = (token: string) => {
  localStorage.setItem(REFRESH_TOKEN_KEY, token)
}

export const clearLocalRefreshToken = () => {
  localStorage.removeItem(REFRESH_TOKEN_KEY)
}

export const getLocalRefreshToken = () => {
  return localStorage.getItem(REFRESH_TOKEN_KEY)
}

// === token and backend clients init

const REFRESH_TOKEN_KEY = 'dashboard_rt'

// dev
// const serverUrl = 'https://skyarc-dev.ru/api/v1'
const serverUrl = 'https://skyarc.ru/api/v1'
const signInUrl = `${serverUrl}/user/login`
// const signInUrl = `https://skyarс.ru/api/v1/user/login`
const signOutUrl = `${serverUrl}/user/logout`
const refreshTokenUrl = `${serverUrl}/user/token`

// prom
// const serverUrl = 'https://skyarc.ru/api'
// const refreshTokenUrl = `${serverUrl}/token`
// const signInUrl = `${serverUrl}/login`
// const signOutUrl = `${serverUrl}/logout`

const oldToken: string | undefined = getLocalRefreshToken() || undefined
export const tokenClient: TokenClient = new SimpleTokenClient(
  signInUrl,
  signOutUrl,
  refreshTokenUrl,
  oldToken
)

const tokenClbk: TokenListener = {
  onTokenUpdated: async (token: Token) => {
    console.log(
      'api token = updated, update token and set new localToken',
      new Date(),
      token
    )

    // if (token && !token?.roles?.includes('ROLE_STATISTICS')) {
    //   console.log('roles !include statistics', token?.roles)
    //   clearLocalRefreshToken()
    //   await backendClient.logout()
    //   router.replace('/login')
    //   console.log('after role logout ', backendClient.isAuthenticated())
    // } else

    if (token) {
      setLocalRefreshToken(token.refresh)
    } else {
      // if token undefined
      clearLocalRefreshToken()
    }
  },
  onError: () => {
    console.log('api token = error, remove token and localStor token')
    // todo: tokenClient self clear if error?
    // tokenClient.clearToken()
    clearLocalRefreshToken()
  },
}
tokenClient.setListener(tokenClbk)

export const backendClient: BackendClient = new SimpleBackendClient(tokenClient)

// fetch wrapper

export const alterFetch = async <T>(url: string, method: string, body?: T) => {
  try {
    const response = await backendClient.sendRequest<T>(
      `${serverUrl}${url}`,
      method,
      {
        json: true,
        body: JSON.stringify(body) || undefined,
      }
    )
    return response
  } catch (err) {
    const error = getErrorObject(err)
    console.log(error.message)
    throw error
  }
}

export const get = async <T>(url: string) => await alterFetch<T>(url, 'get')

export const post = async <T>(url: string, data?: T) =>
  await alterFetch<T>(url, 'post', data)

export const put = async <T>(url: string, data?: T) =>
  await alterFetch<T>(url, 'put', data)

export const getErrorObject = (err: unknown): ServerError => {
  if (err instanceof Error && err.message !== 'Failed to fetch') {
    try {
      const error = JSON.parse(err.message)
      return error ? { ...error, message: error.error } : error
    } catch {
      return { message: err.message }
    }
  }
  return { message: 'NetworkError' }
}

// helpers
export const getAllDataPages = async (dataUrl: string) => {
  let currentPage = 0
  let amountDataOnPage = 0
  const totalData: any[] = []

  do {
    const data: any = await get(dataUrl + `?page=${currentPage}`)
    amountDataOnPage = data.length
    currentPage++
    totalData.push(...data)
  } while (amountDataOnPage === 100)

  return totalData
}

import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import MainLayout from '@/layouts/MainLayout.vue'
import AuthPage from '@/pages/AuthPage.vue'
import NotFoundPage from '@/pages/NotFoundPage.vue'
import OverallDashboardPage from '@/pages/OverallDashboardPage.vue'
import LocationsPage from '@/pages/LocationsPage.vue'
import ContactsPage from '@/pages/ContactsPage.vue'
import GroupPage from '@/pages/GroupPage.vue'
import PilotFlights from '@/pages/PilotFlightsPage.vue'
import UserPage from '@/pages/UserPage.vue'
import InfoPage from '@/pages/InfoPage.vue'
import { backendClient } from '@/api/api'
import PlansDashboardPage from '@/pages/PlansDashboardPage.vue'
import DronesPage from '@/pages/DronesPage.vue'

const routes: RouteRecordRaw[] = [
  { path: '/login', component: AuthPage },
  { path: '/:notFound(.*)', component: NotFoundPage },
  {
    path: '/',
    redirect: '/overall-dashboard',
    component: MainLayout,
    beforeEnter: async () => {
      console.log('get token')
      try {
        //@ts-ignore
        const hasRole = await backendClient.hasRole('ROLE_STATISTICS')
        console.log('roles router ', hasRole)
        if (!hasRole) {
          return { path: '/login' }
        }
      } catch {
        return { path: '/login' }
      }
    },
    children: [
      { path: '/overall-dashboard', component: OverallDashboardPage },
      { path: '/plans-dashboard', component: PlansDashboardPage },
      { path: '/locations', component: LocationsPage },
      { path: '/contacts', component: ContactsPage },
      { path: '/group', component: GroupPage },
      { path: '/pilot-flights', component: PilotFlights },
      { path: '/drones', component: DronesPage },
      { path: '/user', component: UserPage },
      { path: '/info', component: InfoPage },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
  linkActiveClass: 'active',
})

export default router

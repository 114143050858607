import { alterFetch, get, getAllDataPages, post } from '@/api/api'
import {
  Interval,
  DroneResponse,
  PlanResponse,
  UserResponse,
  UserFlightResponse,
} from '@/api/statistics/interface'

// api

export const getPlans = async () => {
  const data: PlanResponse[] = await getAllDataPages('/statistics/plan')
  return data
}

export const getDrones = async () => {
  const data: DroneResponse[] = await getAllDataPages('/statistics/drone')
  return data
}

export const getUsers = async () => {
  const data: UserResponse[] = await getAllDataPages('/statistics/user')
  return data
}

export const getPlanPoints = async () => {
  const data: any = await alterFetch('/statistics/plans/points', 'get')

  return data
}

export const getUserFlights = async (intervals: Interval[]) => {
  const data: any = await alterFetch('/statistics/user/flightInfo', 'post', {
    intervals: intervals,
  })

  return data
}

export const getPlanRegistration = async (intervals: Interval[]) => {
  const data: any = await alterFetch(
    '/statistics/plans/registrations',
    'post',
    {
      intervals: intervals,
    }
  )

  return data
}

export const getUserRegistration = async (intervals: Interval[]) => {
  const data: any = await alterFetch(
    '/statistics/users/registrations',
    'post',
    {
      intervals: intervals,
    }
  )

  return data
}

export const getDroneRegistration = async (intervals: Interval[]) => {
  const data: any = await alterFetch(
    '/statistics/drones/registrations',
    'post',
    {
      intervals: intervals,
    }
  )

  return data
}

export const getModePlans = async (intervals: Interval[]) => {
  const data: any = await alterFetch('/statistics/plans/modes', 'post', {
    intervals: intervals,
  })

  return data
}
export const getRegionPlans = async (intervals: Interval[]) => {
  const data: any = await alterFetch('/statistics/plans/regions', 'post', {
    intervals: intervals,
  })

  return data
}

export const getStatePlans = async (intervals: Interval[]) => {
  const data: any = await alterFetch('/statistics/plans/states', 'post', {
    intervals: intervals,
  })

  return data
}
